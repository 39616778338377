import createBehavior from '@js/functions/createBehavior.js';

const formInputBehavior = createBehavior(
  'form-input',
  {
    isTypeTel() {
      return this.type === 'tel'
    },
    isTypeDate() {
      return this.type === 'input-date' || this.type === 'date'
    },
    formatTelInput() {
      this.node.value = this.node.value.replace(/\D+/g, '').slice(0, 14);
    },
    // Format date input as DD/MM/YYYY
    formatDateInput() {
      const rawValue = this.node.value.replace(/\D+/g, '').slice(0, 8); // Limit to 8 digits
      let formattedValue = rawValue;

      if (rawValue.length > 4) {
        formattedValue = `${rawValue.slice(0, 2)}/${rawValue.slice(2, 4)}/${rawValue.slice(4)}`;
      } else if (rawValue.length > 2) {
        formattedValue = `${rawValue.slice(0, 2)}/${rawValue.slice(2)}`;
      }

      this.node.value = formattedValue;
    },
    handleDateBackspace(event) {
      if (event.key === 'Backspace') {
        const { selectionStart } = this.node;

        // Check if the cursor is right after a slash (/) and handle deletion correctly
        if ([3, 6].includes(selectionStart) && this.node.value[selectionStart - 1] === '/') {
          event.preventDefault();
          this.node.value = this.node.value.slice(0, selectionStart - 2) + this.node.value.slice(selectionStart);
          this.node.setSelectionRange(selectionStart - 2, selectionStart - 2);
        }
      }
    },
  },
  {
    init() {
      this.type = this.node.getAttribute('type');

      if (this.isTypeTel()) {
        this.node.addEventListener('input', this.formatTelInput);
      } else if (this.isTypeDate()) {
        this.node.addEventListener('input', this.formatDateInput);
        this.node.addEventListener('keydown', this.handleDateBackspace);
      }
    },
    destroy() {
      if (this.isTypeTel()) {
        this.node.removeEventListener('input', this.formatTelInput);
      } else if (this.isTypeDate()) {
        this.node.removeEventListener('input', this.formatDateInput);
        this.node.removeEventListener('keydown', this.handleDateBackspace);
      }
    }
  }
);

export default formInputBehavior;
