import createBehavior from '@js/functions/createBehavior.js'
import dataLayerPush from '@js/functions/dataLayerPush.js'
import ListOption from '@components/list/option/list-option.twig'
import ListCart from '@components/list/cart/list-cart.twig'
import SidebarPriceInfo from '@components/sidebar/price-info/sidebar-price-info.twig'
import axios from 'axios'
import ax from '@js/functions/axios.js'
import barba from '@barba/core'
import flatpickr from 'flatpickr'

const cartBehavior = createBehavior(
  'cart',
  {
    bindUI () {
      this.ui = {}

      this.ui.sidebar = document.querySelector(`#${this.sidebarId}`)
      this.ui.breadcrumb = this.getChild('breadcrumb')
      this.ui.breadcrumbMembership = this.getChild('breadcrumb-membership')
      this.ui.sidebarContent = this.getChild('content')
      this.ui.sidebarScroll = this.getChild('scroll')

      this.ui.infos = this.getChild('infos')
      this.ui.priceinfo = this.getChild('priceinfo')
      this.ui.price = this.getChild('price')
      this.ui.paymentBlock = this.getChild('payment-block')
      this.ui.infosText = this.getChild('infos-text')
      this.ui.infosMembership = this.getChild('infos-membership')

      this.ui.stepContainer = this.getChild('step-container')
      this.ui.steps = this.getChildren('step')
      this.ui.stepsBtns = this.getChildren('step-btn')

      this.ui.stepPayment = this.getChild('step-payment')
      this.ui.formPaymentRows = this.getChildren('step-payment-row')

      this.ui.addToCartBtn = this.getChild('add-to-cart-btn')
      this.ui.removeToCartBtn = this.getChildren('remove-to-cart-btn')

      this.ui.banner = this.getChild('banner')
      this.ui.bannerText = this.getChild('banner-text')
      this.ui.bannerClose = this.getChild('banner-close')

      this.ui.formMembership = this.getChild('form-membership')
      this.ui.formMembershipForm = this.ui.formMembership.querySelector('form')
      this.ui.formMembershipTitleNew = this.getChild('membership-title-new')
      this.ui.formMembershipTitleRenew = this.getChild('membership-title-renew')
      this.ui.formMembershipTitleRenewMobile = document.querySelector('.form__fieldset-description-mobile')
      this.ui.membershipInfoNew = this.getChild('membership-info-new')
      this.ui.inputsMembership = this.getChildren('input-membership')
      this.ui.inputEmail = document.querySelector('#email')
      this.ui.inputEmailMembership = document.querySelector('#i-buyer-email')
      this.ui.submitMembership = this.getChild('submit-membership')
      this.ui.tablePrices = this.getChild('table-prices')

      this.ui.mobile = this.getChild('mobile')
      this.ui.mobileStepCurrent = this.getChild('mobile-step-current')
      this.ui.mobileStepTotal = this.getChild('mobile-step-total')
      this.ui.mobileBtnNextStep = this.getChild('mobile-btn-next-step')
      this.ui.mobileStepTitle = this.getChild('mobile-step-title')
      this.ui.mobileInfos = this.getChild('mobile-infos')
      this.ui.mobileInfosText = this.getChild('mobile-infos-text')
      this.ui.mobileInfosDate = this.getChild('mobile-infos-date')
      this.ui.mobileInfosPrices = this.getChild('mobile-infos-prices')

      this.ui.mobileFooter = this.getChild('mobile-footer')
      this.ui.mobileSubmitMembership = this.getChild('mobile-submit-membership')
    },
    bindEvents () {
      // Add event listener when we open the sidebar.
      document.addEventListener('sidebar:open', this.sidebarOpenHandler, false)

      // Add event listener when we close the sidebar.
      document.addEventListener('sidebar:close', this.sidebarCloseHandler, false)

      // Add event listener when datepicker is updated.
      document.addEventListener('datepicker:updated', this.datepickerUpdatedHandler, false)

      // Add event listener when page is updated.
      document.addEventListener('page:updated', this.rebindUIandEvents, false)

      // Add event listener for Stripe errors.
      document.addEventListener('stripe:error', this.stripErrorHandler, false)

      // Add event listener for Adyen errors
      document.addEventListener('adyen:error', this.adyenErrorHandler, false)

      document.addEventListener('transaction:error', this.transactionErrorHandler, false)

      // Add event when order is completed.
      document.addEventListener('stripe:orderCompleted', this.orderCompletedHandler, false)

      document.addEventListener('adyen:confirmOrder', this.handleAdyenConfirmOrder, false)

      document.addEventListener('adyen:transactionStarted', this.handleAdyenTransactionStarted, false)

      // Add change event on input membership.
      if (this.ui.inputsMembership && this.ui.inputsMembership.length) {
        Array.from(this.ui.inputsMembership).map(input => {
          input.addEventListener('input', this.inputMembershipHandler, false)
        })
      }

      // Add click event on step btns.
      if (this.ui.stepsBtns && this.ui.stepsBtns.length) {
        Array.from(this.ui.stepsBtns).map(btn => {
          btn.addEventListener('click', this.stepBtnClickHandler, false)
        })
      }

      // Add click event on add to bag btn.
      if (this.ui.addToCartBtn) {
        this.ui.addToCartBtn.addEventListener('click', this.addToCart, false)
      }

      // Add click event on edit to bag btn.
      if (this.ui.editToCartBtn) {
        this.ui.editToCartBtn.addEventListener('click', this.editToCart, false)
      }

      // Add click event on remove to bag btn.
      if (this.ui.removeToCartBtn && this.ui.removeToCartBtn.length) {
        this.ui.removeToCartBtn.forEach(btn => {
          btn.addEventListener('click', this.removeToCart, false)
        })
      }

      // Add click event on mobile next step btn.
      if (this.ui.mobileBtnNextStep) {
        this.ui.mobileBtnNextStep.addEventListener('click', this.goToNextStep, false)
      }

      window.addEventListener('resize', this.handleWindowResize, false)
      document.addEventListener('adyen:relayout', this.handleAdyenRelayout, false)

      this.node.addEventListener('datepicker-single:loading', this.handleDatepickerSingleLoading, false)
      this.node.addEventListener('datepicker-single:loaded', this.handleDatepickerSingleLoaded, false)

      this.ui.bannerClose.addEventListener('click', this.handleBannerCloseClick)
    },
    rebindUIandEvents () {
      // Remove all events.
      if (this.ui.removeToCartBtn && this.ui.removeToCartBtn.length) {
        this.ui.removeToCartBtn.forEach(btn => {
          btn.removeEventListener('click', this.removeToCart)
        })
      }

      // Get UI.
      this.ui.removeToCartBtn = document.querySelectorAll('[data-cart-remove-to-cart-btn]')

      // Bind again.
      if (this.ui.removeToCartBtn && this.ui.removeToCartBtn.length) {
        this.ui.removeToCartBtn.forEach(btn => {
          btn.addEventListener('click', this.removeToCart, false)
        })
      }
    },
    analytics (stepName) {
      // Analytics GTM steps
      // hours (horaire) is also a step, is a special case
      const steps = ['date', 'billet', 'paiement']
      const virtualPage = ['funnel']
      if (this.productType && this.productType === 'membership') {
        virtualPage.push('abonnements')
      }
      if (stepName) {
        virtualPage.push(stepName)
      } else {
        virtualPage.push(steps[this.step - 1])
      }
      const obj = {
        event: 'virtual_page_view',
        page_category: 'funnel',
        virtual_page: `/${virtualPage.join('/')}/`,
      }
      dataLayerPush(obj)
    },
    handleAdyenConfirmOrder (e) {
      this.confirmOrder(e.detail.redirect_url)
    },
    handleAdyenTransactionStarted () {
      this.resetErrorBanner()
    },
    handleBannerCloseClick (e) {
      e.preventDefault()
      this.ui.banner.classList.remove('is-visible')
      this.updateStepHeight()
    },
    sidebarOpenHandler (e) {
      let shouldCheckAmount = e.detail.shouldCheckAmount != null ? e.detail.shouldCheckAmount : true

      // If sidebar cart is open, fetch event.
      if (e.detail.id === this.sidebarId) {

        // Show loader.
        this.ui.sidebar.classList.add(this.sidebarLoadingKlass)

        // If we have a type.
        if (e.detail.productType && e.detail.productType === 'membership') {

          // Set membership UI mode
          const canBeAGift = e.detail.productMembership !== 'renew' && e.detail.productCanBeGift
          this.activeMembershipFieldset({
            canBeAGift: canBeAGift,
            giftChecked: e.detail.productIsGift,
            isDigital: e.detail.productIsDigital,
            minHolderAge: e.detail.productGiftMinAge,
            maxHolderAge: e.detail.productGiftMaxAge,
            holderMessage: e.detail.holderMessage,
          })

          this.ui.formMembership.classList.remove('is-hidden')
          this.ui.tablePrices.classList.add('is-hidden')
          if (this.ui.infosText) this.ui.infosText.classList.add('is-hidden')
          if (this.ui.infosMembership) this.ui.infosMembership.classList.remove('is-hidden')

          if (e.detail.productMembership == 'renew') {
            this.ui.membershipInfoNew.classList.add('is-hidden')
            this.ui.formMembershipTitleNew.classList.add('is-hidden')
            this.ui.formMembershipTitleRenew.classList.remove('is-hidden')
            this.ui.formMembershipTitleRenewMobile.classList.add('is-hidden')
          } else {
            this.ui.membershipInfoNew.classList.remove('is-hidden')
            this.ui.formMembershipTitleNew.classList.remove('is-hidden')
            this.ui.formMembershipTitleRenew.classList.add('is-hidden')
            this.ui.formMembershipTitleRenewMobile.classList.remove('is-hidden')
          }

          // Save starting step.
          this.startingStep = 2

          // Update step.
          this.step = 2

          // Save product type.
          this.productType = e.detail.productType
          this.productMembership = e.detail.productMembership

          // Update cart UI.
          this.updateCartUI()

          // Fetch dates.
          this.APIProductGetDates({
            productId: e.detail.productId,
          })

          // tell gtm
          this.analytics()

        } else {

          // Set membership UI mode
          this.ui.formMembership.classList.add('is-hidden')
          this.ui.tablePrices.classList.remove('is-hidden')
          if (this.ui.infosText) this.ui.infosText.classList.remove('is-hidden')
          if (this.ui.infosMembership) this.ui.infosMembership.classList.add('is-hidden')

          // Check if we have a step.
          if (e.detail.step) {

            // Save starting step.
            this.startingStep = e.detail.step

            // Update step for the cart.
            this.step = e.detail.step

            // Based on the value.
            switch (this.step) {
              case 3:
                this.updateCartUI()
                if (shouldCheckAmount) {
                  this.APICartCheckAmount()
                }
                break
            }

            // tell gtm
            this.analytics()
          } else {
            this.APIProductGetDates({
              productId: e.detail.productId,
            })

            // tell gtm
            this.analytics()
          }
        }
      }
    },
    sidebarCloseHandler (e) {
      // If sidebar cart is close, reset it.
      if (e.detail.id === this.sidebarId) {
        this.resetSidebar()
      }
    },
    isProductAlreadyAdded () {
      // Init variable.
      let productIndex = null

      // Loop through each product.
      if (this.cart.products) {
        for (let i = 0, j = this.cart.products.length; i < j; i++) {
          if (this.cart.products[i].productId === this.currentProduct.productId) {
            productIndex = i
          }
        }
      }

      // If we have a product index.
      if (productIndex !== null) {
        this.productEdited = this.cart.products[productIndex]
        this.dateSelected = this.cart.products[productIndex].dateSelected
        this.showId = this.cart.products[productIndex].showId
      }

      // If we have a membership product type.
      if (this.productType === 'membership') {

        // Save showId.
        this.showId = this.currentProduct.dates[0].hours[0].show_id

        // Get prices.
        this.APIProductGetPrices()

      } else {
        // If this product only has one date & time
        // then we can use it immediately
        if (this.currentProduct.dates.length === 1 && this.currentProduct.dates[0].hours.length === 1) {

          if (productIndex == null) {
            let hour = this.currentProduct.dates[0].hours[0]
            this.showId = hour.show_id

            // Get formatted date using flatpicker utils (without instantiating a datepicker itself)
            const rawDate = flatpickr.parseDate(hour.start_date)
            let fpLocale = flatpickr.l10ns[this.options.locale]
            const fullDay = fpLocale.weekdays.longhand[rawDate.getDay()]
            const fullDate = rawDate.getDate()
            const fullMonth = fpLocale.months.longhand[rawDate.getMonth()]
            const formattedDate = `${fullDay} ${fullDate} ${fullMonth}`

            this.dateSelected = {
              hourStr: hour.start_time,
              showId: hour.show_id,
              startDate: hour.start_date,
              dateFormatted: formattedDate,
              dateStr: hour.date,
              dateRaw: [rawDate],
            }

            this.updateCartObject()
          }

          if (this.step === 1) {
            this.step = 2
            this.moveSteps()
            return
          }
        }

        // Build sidebar.
        this.buildDatepicker()

        // Steps validaton.
        this.stepsValidation()

        // Update steps.
        this.updateCartUI()
      }
    },
    buildDatepicker () {
      // Emit event.
      document.dispatchEvent(new CustomEvent('cart:productGet', {
        detail: {
          dates: this.currentProduct.dates,
          dateSelected: this.dateSelected ? this.dateSelected : null,
          productId: this.currentProduct.productId,
        },
      }))
    },
    buildPrices () {
      // Init prices variable.
      let prices = null

      // Init targetProduct variable.
      let targetProduct = null

      // Check if we already have this product.
      for (let i = 0, j = this.cart.products.length; i < j; i++) {
        if (this.cart.products[i].productId === this.currentProduct.productId) {
          targetProduct = this.cart.products[i]
        }
      }

      // Add prices.
      prices = this.currentProduct.prices.map(price => {

        // Init priceFound variable.
        let priceFound = null

        // Loop through each prices of our target and find corresponding one.
        if (targetProduct && targetProduct.prices && targetProduct.prices.length) {
          for (let i = 0, j = targetProduct.prices.length; i < j; i++) {
            if (targetProduct.prices[i].priceId === price.priceId) {
              priceFound = {
                ...price,
                quantity: targetProduct.prices[i].quantity,
              }
            }
          }
        }

        // If we didn't find the corresponding price inside the target product, fallback on the default one.
        if (!priceFound) {
          priceFound = price
        }

        // Return priceFound.
        return priceFound
      })

      // Add shuttle prices.
      if (this.shuttle && this.shuttle.prices && this.shuttle.prices.length) {

        // shuttleQuantity
        let shuttleQuantity = 0

        // Check if we already have a shuttle product with the same date.
        if (this.cart.products && this.cart.products.length) {
          this.cart.products.forEach(product => {
            if (product.showId === this.shuttle.prices[0].showId) {
              shuttleQuantity = product.prices[0].quantity
            }
          })
        }

        // Push shuttle price.
        prices.push({
          type: 'shuttle',
          ...this.shuttle.prices[0],
          quantity: shuttleQuantity,
        })
      }

      // Get price markup.
      const priceMarkup = ListOption({
        items: prices,
        locale: this.options['locale'],
      })

      // Remove event listener if we have existing ones.
      if (this.ui.priceInputs) {
        Array.from(this.ui.priceInputs).map(input => {
          input.removeEventListener('change', this.updateCartObject)
        })
      }

      // Append markup inside DOM.
      this.ui.price.innerHTML = priceMarkup

      // Get price inputs.
      this.ui.priceInputs = this.ui.price.querySelectorAll('input')

      // Add change handler on price inputs.
      if (this.ui.priceInputs) {
        Array.from(this.ui.priceInputs).map(input => {
          input.addEventListener('change', this.updateCartObject, false)
        })
      }
    },
    datepickerUpdatedHandler (e) {
      // If it's the cart datepicker.
      if (e.detail.type === 'cart') {
        this.dateSelected = e.detail.dateSelected
        this.showId = e.detail.dateSelected ? e.detail.dateSelected.showId : null
        this.updateCartObject()
        this.updateStepHeight()
      }
    },
    stepsValidation () {

      // Get current step.
      const currentStep = this.ui.steps[this.step - 1]

      // Get step button.
      let stepBtn = currentStep.querySelector('[data-cart-step-btn]')

      if (this.step == 2 && this.productType == 'membership') {
        stepBtn = currentStep.querySelectorAll('[data-cart-step-btn]')[0]
      } else if (this.step == 2) {
        stepBtn = currentStep.querySelectorAll('[data-cart-step-btn]')[1]
      }

      // If we don't have any step button, return.
      if (!stepBtn) return

      // Init isValid variable.
      let isValid = false

      // Based on the step we are.
      switch (this.step) {

        // Dates and hours : need to have a date selected and an hour selected
        case 1:
          if (this.dateSelected && this.dateSelected.dateStr && this.dateSelected.hourStr) {
            isValid = true
          }
          break

        // Price : need to have at least 1 price (which is not a shuttle one)
        case 2:

          if (this.productType == 'membership') {

            isValid = false
            // leave the validation in inputMembershipHandler
            this.inputMembershipHandler()

          } else {
            if (this.cart.products && this.cart.products.length) {
              this.cart.products.map(product => {
                if (this.currentProduct.productId === product.productId) {
                  if (product.prices && product.prices.length) {
                    isValid = true
                  }
                }
              })
            }
          }
          break
      }

      // Update step btn and add to bag btn..
      if (isValid) {

        stepBtn.removeAttribute('disabled')
        this.ui.mobileBtnNextStep.removeAttribute('disabled')
        if (this.ui.addToCartBtn) {
          this.ui.addToCartBtn.removeAttribute('disabled')
        }
      } else {
        stepBtn.setAttribute('disabled', '')
        this.ui.mobileBtnNextStep.setAttribute('disabled', '')
        if (this.ui.addToCartBtn) {
          this.ui.addToCartBtn.setAttribute('disabled', '')
        }
      }
    },
    stepBtnClickHandler (e) {
      // If we click on a breadcrumb link.
      if (e.target.classList.contains('breadcrumb__link')) {

        // Init target product.
        let targetProduct = null

        // Find the target product.
        if (this.cart && this.cart.products && this.cart.products.length) {
          this.cart.products.forEach(product => {
            if (product.productId === this.currentProduct.productId) {
              targetProduct = product
            }
          })
        }

        // Update current product.
        this.productEdited = targetProduct
      }

      // Show loader
      this.ui.sidebar.classList.add(this.sidebarLoadingKlass)

      // Get index.
      const index = e.currentTarget.getAttribute('data-cart-step-index') * 1

      // Update step.
      this.step = index

      // Once the loader is displayed, update steps.
      this.moveSteps()
    },
    activeMembershipFieldset (opts = {
      canBeAGift: false,
      giftChecked: false,
      isDigital: false,
      minHolderAge: null,
      maxHolderAge: null,
    }) {
      this.ui.formMembershipFielsets = Array.from(this.ui.formMembership.querySelectorAll('fieldset')).reduce((acc, fieldset) => fieldset.dataset.fieldsetName ? ({
        ...acc,
        [fieldset.dataset.fieldsetName]: fieldset,
      }) : acc, {})

      this.ui.formMembershipDeliveryHolderOption = this.ui.formMembership.querySelector('[data-delivery-field="holder_address_delivery"]')
      this.ui.formMembershipHolderBirthDate = this.ui.formMembership.querySelector('input[name="holder_birthday"]')
      this.ui.formMembershipBirthDate = this.ui.formMembership.querySelector('input[name="birthday"]')
      this.ui.formMembershipBuyerBirthDate = this.ui.formMembership.querySelector('input[name="buyer_birthday"]')
      this.ui.formMembershipGiftCheckbox = this.ui.formMembership.querySelector('input[name="gift_pass"]')

      this.ui.formMembershipDeliveryHolderOption?.classList.add('field__hidden')

      this.ui.formMembershipHiddenFieldsetKeys = ['holder-address', 'delivery']
      this.ui.formMemberListenInputsKeys = []
      this.ui.formMemberListenInputs = []
      this.ui.formMembershipCheckAge = {
        check: false,
        inputs: [],
      }

      this.ui.formMemberDigitalProduct = false;

      // clean birthday input field on open
      this.updateBirthdayInputMessage(this.ui.formMembershipBirthDate, true)
      this.updateBirthdayInputMessage(this.ui.formMembershipHolderBirthDate, true)
      this.updateBirthdayInputMessage(this.ui.formMembershipBuyerBirthDate, true)

      this.defineBirthdayMinMax(opts)

      if (this.ui.formMembershipCheckAge.check) {
        this.updateBirthdayInputMessage(this.ui.formMembershipBirthDate)
        this.ui.formMembershipCheckAge.inputs.push('birthday')
      } else {
        this.updateBirthdayInputMessage(this.ui.formMembershipBirthDate, true)
      }

      if ('canBeAGift' in opts && !opts.canBeAGift) {
        this.ui.formMembershipHiddenFieldsetKeys.push('gift-pass')
        this.ui.formMembershipHiddenFieldsetKeys.push('holder')
      } else {
        this.ui.formMemberListenInputsKeys.push('gift-pass')
        if ('giftChecked' in opts) {
          if (this.ui.formMembershipCheckAge.check) {
            this.updateBirthdayInputMessage(this.ui.formMembershipHolderBirthDate)
            this.updateBirthdayInputMessage(this.ui.formMembershipBuyerBirthDate)
            // reset inputs to remove initial rules put on init
            this.ui.formMembershipCheckAge.inputs = []
            this.ui.formMembershipCheckAge.inputs.push('birthday')
          } else {
            this.updateBirthdayInputMessage(this.ui.formMembershipHolderBirthDate, true)
            this.updateBirthdayInputMessage(this.ui.formMembershipBuyerBirthDate, true)
          }

          if (opts.giftChecked) {
            this.ui.formMembershipGiftCheckbox.checked = true
            this.ui.formMembershipDeliveryHolderOption?.classList.remove('field__hidden')
            const index = this.ui.formMembershipHiddenFieldsetKeys.indexOf('delivery');
            if (index != -1) {
              this.ui.formMembershipHiddenFieldsetKeys.splice(index, 1);
            }

            // Remove age restriction on buyer birthday input
            if (this.ui.formMembershipCheckAge.check) {
              this.ui.formMembershipCheckAge.inputs = []
              this.ui.formMembershipCheckAge.inputs.push('holder_birthday')
              this.ui.formMembershipCheckAge.inputs.push('buyer_birthday')
            }
            this.updateBirthdayInputMessage(this.ui.formMembershipBirthDate, true)
          } else {
            this.ui.formMembershipHiddenFieldsetKeys.push('holder')
          }
        }
      }

      if ('isDigital' in opts && opts.isDigital) {
        this.ui.formMemberDigitalProduct = true;
        this.ui.formMembershipHiddenFieldsetKeys.push('delivery')
        this.ui.formMembershipHiddenFieldsetKeys.push('buyer-address')
      } else {
        this.ui.formMemberListenInputsKeys.push('delivery')
      }

      for (const [key, fieldset] of Object.entries(this.ui.formMembershipFielsets)) {
        if (this.ui.formMembershipHiddenFieldsetKeys.includes(key)) {
          fieldset?.classList.add('form__fieldset--hidden')
        } else {
          fieldset?.classList.remove('form__fieldset--hidden')
        }

        if (this.ui.formMemberListenInputsKeys.includes(key)) {
          const inputs = Array.from(fieldset.querySelectorAll('input') || [])
          inputs.forEach(input => {
            input.addEventListener('input', this.onMemberShipInputChange)
            this.ui.formMemberListenInputs.push(input)
          })
        }
      }
    },
    defineBirthdayMinMax (opts) {
      this.ui.holderBirthDateMessage = ''
      this.ui.buyerBirthDateMessage = ''
      const hasMinAge = 'minHolderAge' in opts && opts.minHolderAge && typeof Number(opts.minHolderAge) === 'number'
      if (hasMinAge) {
        this.ui.formMembershipCheckAge.check = true
        this.ui.formMembershipCheckAge.min = Number(opts.minHolderAge)
        this.ui.holderBirthDateMessage = window.A17?.cartLabels?.hodlerBirthdate.min?.replace('%min%', opts.minHolderAge) || ''
        this.ui.buyerBirthDateMessage = window.A17?.cartLabels?.buyerBirthdate.min?.replace('%min%', opts.minHolderAge) || ''
      }

      const hasMaxAge = 'maxHolderAge' in opts && opts.maxHolderAge && typeof Number(opts.maxHolderAge) === 'number'
      if (hasMaxAge) {
        this.ui.formMembershipCheckAge.check = true
        this.ui.formMembershipCheckAge.max = Number(opts.maxHolderAge)
        this.ui.holderBirthDateMessage = window.A17?.cartLabels?.hodlerBirthdate.max?.replace('%max%', opts.maxHolderAge) || ''
        this.ui.buyerBirthDateMessage = window.A17?.cartLabels?.buyerBirthdate.max?.replace('%max%', opts.maxHolderAge) || ''
      }

      if (hasMinAge && hasMaxAge) {
        this.ui.formMembershipCheckAge.check = true
        this.ui.holderBirthDateMessage = window.A17?.cartLabels?.hodlerBirthdate.between?.replace('%min%', opts.minHolderAge).replace('%max%', opts.maxHolderAge) || ''
        this.ui.buyerBirthDateMessage = window.A17?.cartLabels?.buyerBirthdate.between?.replace('%min%', opts.minHolderAge).replace('%max%', opts.maxHolderAge) || ''
      }

      // Always initialize buyer birthday validation
      if (this.ui.formMembershipCheckAge.check) {
        this.ui.formMembershipCheckAge.inputs = ['birthday', 'holder_birthday', 'buyer_birthday']
        this.updateBirthdayInputMessage(this.ui.formMembershipBuyerBirthDate)
      }
    },
    updateBirthdayInputMessage (input, clean = false) {
      const parent = input?.closest('.form__element')
      if (parent) {
        if (clean) {
          parent.classList.remove('has-error')
        }
        const messageWrapper = parent.querySelector('.form__message')
        if (messageWrapper) {
          messageWrapper.textContent = clean ? '' : (this.ui.holderBirthDateMessage || this.ui.buyerBirthDateMessage || '')
        }
      }
    },
    checkBuyerBirthdayDate (input) {
      const value = input.value
      let parts = value.split('/')
      if (parts.length !== 3) {
        return false
      }
      let date = new Date(parts[2], parts[1] - 1, parts[0])
      const birthdayInCurrentYear = new Date()
      birthdayInCurrentYear.setMonth(date.getMonth())
      birthdayInCurrentYear.setDate(date.getDate())

      const now = new Date()
      const removeOneYear = birthdayInCurrentYear > now
      const age = Number(now.getFullYear()) - Number(date.getFullYear()) - (removeOneYear ? 1 : 0)
      const parent = input?.closest('.form__element')
      let isAgeValid = true
      if (this.ui.formMembershipCheckAge.min && this.ui.formMembershipCheckAge.max) {
        isAgeValid = age >= this.ui.formMembershipCheckAge.min && age <= this.ui.formMembershipCheckAge.max
      } else if (this.ui.formMembershipCheckAge.min) {
        isAgeValid = age >= this.ui.formMembershipCheckAge.min
      } else if (this.ui.formMembershipCheckAge.max) {
        isAgeValid = age <= this.ui.formMembershipCheckAge.max
      }

      if (isAgeValid) {
        parent?.classList.remove('has-error')
      } else {
        parent?.classList.add('has-error')
      }

      return isAgeValid
    },
    checkHolderBirthdayDate (input) {
      const value = input.value
      let parts = value.split('/')
      if (parts.length !== 3) {
        return false
      }
      let date = new Date(parts[2], parts[1] - 1, parts[0])
      const birthdayInCurrentYear = new Date()
      birthdayInCurrentYear.setMonth(date.getMonth())
      birthdayInCurrentYear.setDate(date.getDate())

      const now = new Date()
      const removeOneYear = birthdayInCurrentYear > now
      const age = Number(now.getFullYear()) - Number(date.getFullYear()) - (removeOneYear ? 1 : 0)
      const parent = input?.closest('.form__element')
      let isAgeValid = true
      if (this.ui.formMembershipCheckAge.min && this.ui.formMembershipCheckAge.max) {
        isAgeValid = age >= this.ui.formMembershipCheckAge.min && age <= this.ui.formMembershipCheckAge.max
      } else if (this.ui.formMembershipCheckAge.min) {
        isAgeValid = age >= this.ui.formMembershipCheckAge.min
      } else if (this.ui.formMembershipCheckAge.max) {
        isAgeValid = age <= this.ui.formMembershipCheckAge.max
      }

      if (isAgeValid) {
        parent?.classList.remove('has-error')
      } else {
        parent?.classList.add('has-error')
      }

      return isAgeValid
    },
    onMemberShipInputChange (e) {
      switch (e.target.name) {
        case 'gift_pass':
          if (e.target.checked) {
            this.ui.formMembershipFielsets['holder']?.classList.remove('form__fieldset--hidden')
            this.ui.formMembershipDeliveryHolderOption?.classList.remove('field__hidden')
            if (this.ui.formMembershipCheckAge.check) {
              this.ui.formMembershipCheckAge.inputs = []
              this.ui.formMembershipCheckAge.inputs.push('holder_birthday')
              this.ui.formMembershipCheckAge.inputs.push('buyer_birthday')
              this.updateBirthdayInputMessage(this.ui.formMembershipBirthDate, true)
            }
            if (!this.ui.formMemberDigitalProduct){
              this.ui.formMembershipFielsets['delivery']?.classList.remove('form__fieldset--hidden')
              this.ui.formMembershipFielsets['holder-address']?.classList.remove('form__fieldset--hidden')
            }
          } else {
            const holderDeliveryInput = this.formMembershipDeliveryHolderOption?.querySelector('input')
            this.ui.formMembershipFielsets['holder']?.classList.add('form__fieldset--hidden')
            this.ui.formMembershipFielsets['holder-address']?.classList.add('form__fieldset--hidden')
            this.ui.formMembershipDeliveryHolderOption?.classList.add('field__hidden')
            this.ui.formMembershipFielsets['delivery']?.classList.add('form__fieldset--hidden')

            if (this.ui.formMembershipCheckAge.check) {
              this.ui.formMembershipCheckAge.inputs = []
              this.ui.formMembershipCheckAge.inputs.push('birthday')
              this.ui.formMembershipCheckAge.inputs.push('buyer_birthday')  // Always validate buyer birthday
              this.updateBirthdayInputMessage(this.ui.formMembershipBirthDate)
            }

            if (holderDeliveryInput && holderDeliveryInput.checked) {
              this.ui.formMembershipFielsets['buyer-address']?.classList.add('form__fieldset--hidden')

              this.ui.formMemberListenInputs.forEach(input => {
                if (input.name === 'delivery_type' && input.checked) {
                  input.checked = false
                }
              })
            }
          }
          this.inputMembershipHandler()
          this.updateStepHeight()
          break
        case 'delivery_type':
          this.inputMembershipHandler()
          this.updateStepHeight()
          break
      }
    },
    inputMembershipHandler (e) {
      // check all inputs
      let isValidLocal = true
      const isGiftChecked = this.ui.formMembershipGiftCheckbox?.checked || false;

      if (this.ui.inputsMembership && this.ui.inputsMembership.length) {
        Array.from(this.ui.inputsMembership)
          // check for all visible inputs
          .filter(input => input.closest('.form__fieldset--hidden') === null && input.closest('.field__hidden') === null && typeof input.checkValidity === 'function')
          .forEach(input => {
            isValidLocal = isValidLocal && input.checkValidity()
            
            // Check buyer birthday only if gift is NOT checked
            if (input.name === 'buyer_birthday' && input.checkValidity() && !isGiftChecked) {
              const validInput = this.checkBuyerBirthdayDate(input)
              isValidLocal = isValidLocal && validInput
            }
            
            // Check holder birthday only if gift IS checked
            if (input.name === 'holder_birthday' && input.checkValidity() && isGiftChecked) {
              const validInput = this.checkHolderBirthdayDate(input)
              isValidLocal = isValidLocal && validInput
            }
            
            // Check regular birthday field if gift is NOT checked
            if (input.name === 'birthday' && input.checkValidity() && !isGiftChecked) {
              const validInput = this.checkHolderBirthdayDate(input)
              isValidLocal = isValidLocal && validInput
            }
          })
      }
      this.isValid = isValidLocal

      if (this.isValid) {
        this.ui.submitMembership.removeAttribute('disabled')
        this.ui.mobileSubmitMembership.removeAttribute('disabled')
      } else {
        this.ui.submitMembership.setAttribute('disabled', '')
        this.ui.mobileSubmitMembership.setAttribute('disabled', '')
      }
    },
    goToNextStep () {
      // Show loader.
      this.ui.sidebar.classList.add(this.sidebarLoadingKlass)

      // Update step variable.
      this.step++

      // If sidebar scroll exist, scroll top.
      if (this.ui.sidebarScroll) {
        this.ui.sidebarScroll.scroll(0, 0)
      }

      // Update steps.
      this.moveSteps()

      // Blur button.
      setTimeout(() => {

        // Blur button.
        this.ui.mobileBtnNextStep.blur()

      }, this.sidebarTransitionDuration)
    },
    moveSteps () {
      // If we go back to first step.
      if (this.step === 1) {

        // Reset price inputs.
        Array.from(this.ui.priceInputs).map(input => {
          input.value = 0
        })

        // Emit event to update UI for price inputs.
        document.dispatchEvent(new CustomEvent('formQuantity:updateFromOutside'))

        // Remove if we have current product inside cart object.
        if (this.cart.products && this.cart.products[this.currentProduct.productId]) {
          delete this.cart.products[this.currentProduct.productId]
        }

        // Update validation.
        this.stepsValidation()

        // Update breadcrumb.
        this.updateCartUI()

        // Remove loader.
        this.ui.sidebar.classList.remove(this.sidebarLoadingKlass)

      } else if (this.step === 2) {

        if (this.productType == 'membership') {
          this.ui.formMembership.classList.remove('is-hidden')
        } else {
          this.ui.formMembership.classList.add('is-hidden')
        }

        // Get product prices.
        this.APIProductGetPrices()

      } else if (this.step === 3) {

        if (this.productType == 'membership') {

          // Hide form membership
          this.ui.formMembership.classList.add('is-hidden')

          // Hide email from payment form
          Array.from(this.ui.formPaymentRows).map((row, index) => {
            if (row.querySelector('#email')) {
              this.ui.inputEmail.value = this.ui.inputEmailMembership.value ?? null
              row.classList.add('is-hidden')
            }
          })
        } else {
          // Hide email from payment form
          Array.from(this.ui.formPaymentRows).map((row, index) => {
            if (row.querySelector('#email')) {
              row.classList.remove('is-hidden')
            }
          })
        }

        // If we have a cart id.
        if (this.cart.cartId) {
          if (this.productEdited) {
            this.APIProductEdit()
          } else {
            this.APIProductAdd({
              productId: this.currentProduct ? this.currentProduct.productId : null,
            })
          }
        } else {
          this.APICartCreate()
        }

        // Update validation.
        this.stepsValidation()

        // Update breadcrumb.
        this.updateCartUI()
      }

      // tell gtm
      this.analytics()
    },
    updateCartObject (e) {

      // Init entries.
      let entries = []

      // Init prices.
      let prices = []

      // Init prices shuttle.
      let pricesShuttle = []

      // If we have some inputs.
      if (this.ui.priceInputs && this.currentProduct.prices) {

        // Loop through each price input.
        Array.from(this.ui.priceInputs).map(item => {

          // Get show id.
          const showId = item.getAttribute('data-form-quantity-show-id') * 1

          // Get input id.
          const inputId = item.getAttribute('id') * 1

          // Get quantity.
          let quantity = item.value * 1

          // Force quantity for membership passes
          if (this.productType == 'membership') {
            quantity = 1
          }

          // If it's the shuttle.
          if (this.shuttle && this.shuttle.showId === showId) {

            // If we have a quantity.
            if (quantity >= 1) {

              // Push new price.
              pricesShuttle.push({
                priceId: this.shuttle.prices[0].priceId,
                title: this.shuttle.prices[0].title,
                price: this.shuttle.prices[0].price,
                quantity: quantity,
              })

            }

          } else {

            // If we have a quantity.
            if (quantity >= 1) {

              // Init price.
              let price = null

              // Loop through all the price inside the current product.
              for (let i = 0, j = this.currentProduct.prices.length; i < j; i++) {
                if (inputId === this.currentProduct.prices[i].priceId) {
                  price = {
                    priceId: this.currentProduct.prices[i].priceId,
                    title: this.currentProduct.prices[i].title,
                    price: this.currentProduct.prices[i].price,
                    quantity: quantity,
                  }
                }
              }

              // Push price to array.
              if (price) {
                prices.push(price)
              }

            }

          }
        })
      }

      let showPricesText = true

      if (this.currentProduct && this.currentProduct.event && (this.currentProduct.event.showPricesText === false)) {
        showPricesText = false
      }

      // Create product
      let product = {
        productId: this.currentProduct.productId,
        type: this.productType ? this.productType : null,
        showId: this.showId,
        title: this.currentProduct.title,
        image: this.currentProduct.image,
        date: this.dateSelected ? this.dateSelected.dateFormatted : null,
        hour: this.dateSelected ? this.dateSelected.hourStr : null,
        dateSelected: this.dateSelected,
        prices: prices,
        showPricesText: showPricesText,
        eventId: (this.currentProduct && this.currentProduct.event) ? this.currentProduct.event.id : null,
      }

      // Init product shuttle.
      let productShuttle = null

      // Create shuttle product.
      if (this.shuttle && pricesShuttle) {

        // Init date selected.
        let dateSelected = this.dateSelected

        // If date selected exist, update showId.
        if (dateSelected) {
          dateSelected.showId = this.shuttle.showId
        }

        // Build product shuttle.
        productShuttle = {
          productId: this.shuttle.productId,
          type: 'shuttle',
          showId: this.shuttle.showId,
          title: this.shuttle.title,
          image: this.shuttle.image,
          date: dateSelected ? dateSelected.dateFormatted : null,
          hour: dateSelected ? dateSelected.hourStr : null,
          dateSelected: dateSelected,
          prices: pricesShuttle,
          showPricesText: showPricesText,
          eventId: (this.currentProduct && this.currentProduct.event) ? this.currentProduct.event.id : null,
        }
      }

      // Init productIndex variable.
      let productIndex = null
      let shuttleIndex = null

      // Check if we already have this event.
      for (let i = 0, j = this.cart.products.length; i < j; i++) {
        if (this.cart.products[i].productId === product.productId) {
          productIndex = i
        }
        if (productShuttle && this.cart.products[i].productId === productShuttle.productId && this.cart.products[i].dateSelected.dateStr === productShuttle.dateSelected.dateStr) {
          shuttleIndex = i
        }
      }

      // If we already have the product.
      if (productIndex !== null) {
        this.cart.products[productIndex] = product
      } else {
        this.cart.products.push(product)
      }

      // If we have a shuttle index.
      if (shuttleIndex) {
        if (productShuttle && productShuttle.prices && productShuttle.prices.length) {
          // If we already have some entries, add them back.
          if (this.cart.products[shuttleIndex].entries) {
            productShuttle.entries = this.cart.products[shuttleIndex].entries
            this.cart.products[shuttleIndex] = productShuttle
          } else {
            this.cart.products[shuttleIndex] = productShuttle
          }
        } else {
          this.cart.products.splice(shuttleIndex, 1)
        }
      } else if (productShuttle && productShuttle.prices && productShuttle.prices.length) {
        this.cart.products.push(productShuttle)
      }

      // Save latest product.
      this.latestProductAdded = product

      // Update infos.
      this.updateUIInfos()

      // Update UI mobile.
      this.updateCartMobile()

      // Launch stepsValidation
      this.stepsValidation()
    },
    addToCart () {
      // Add loader on sidebar.
      this.ui.sidebar.classList.add(this.sidebarLoadingKlass)

      // If we have a cart id.
      if (this.cart.cartId) {
        if (this.productEdited) {
          this.APIProductEdit({
            closeSidebar: true,
          })
        } else {
          this.APIProductAdd({
            productId: this.currentProduct.productId,
            closeSidebar: true,
            openCartMini: true,
          })
        }
      } else {
        this.APICartCreate({
          closeSidebar: true,
          openCartMini: true,
        })
      }
    },
    removeToCart (e) {
      // Get product id.
      const productId = e.currentTarget.getAttribute('data-product-id') * 1

      // Get item.
      const item = e.currentTarget.closest('.list-product__item')

      // If item exist, add loading class.
      if (item) {
        item.classList.add('is-loading')
      }

      // Init variable.
      let productIndex = null

      // Loop through each product.
      if (this.cart.products) {
        for (let i = 0, j = this.cart.products.length; i < j; i++) {
          if (this.cart.products[i].productId === productId) {
            productIndex = i
          }
        }
      }

      // If we have a product index.
      if (productIndex !== null) {
        this.productEdited = this.cart.products[productIndex]
        this.APIProductDelete({
          productIndex: productIndex,
          item: item,
        })
      }
    },
    updateCartUI (params = {}) {
      // Update UI Infos.
      this.updateUIInfos()

      // Update cart mobile UI.
      this.updateCartMobile()

      // Update UI Steps.
      this.updateUISteps()

      // Update UI Breadcrumb.
      this.updateUIBreadcrumb()
    },
    updateCartMobile () {
      // Get data.
      const data = JSON.parse(this.ui.mobile.getAttribute('data-cart-mobile-data'))

      // Get title.
      const title = this.ui.steps[this.step - 1].querySelector('.sidebar__step-title').textContent

      // Append title inside
      this.ui.mobileStepTitle.textContent = title

      // Create strings.
      let dateString = ''
      let pricesString = ''

      // If we are on step 3, activate paying mode.
      if (this.step === 3) {
        setTimeout(() => {
          this.ui.mobile.classList.add('is-paying')
        }, 300)
      } else {
        this.ui.mobile.classList.remove('is-paying')
      }

      // If we have a date.
      if (this.cart.products && this.cart.products.length && this.currentProduct) {

        // Init targetProduct variable.
        let targetProduct = null
        let shuttleProduct = null

        // Find target product.
        this.cart.products.forEach(product => {
          if (product.productId === this.currentProduct.productId) {
            targetProduct = product
          }
        })

        // Find shuttle product.
        this.cart.products.forEach(product => {
          if (targetProduct && product.type === 'shuttle' && targetProduct.dateSelected.dateStr === product.dateSelected.dateStr) {
            shuttleProduct = product
          }
        })

        // If we have a target product.
        if (targetProduct && targetProduct.prices && targetProduct.prices.length) {

          // Init total.
          let total = 0
          let number = 0

          // Loop through each ticket and add it to the total.
          targetProduct.prices.forEach(price => {
            total += price.price * price.quantity
            number += price.quantity
          })

          // If we have a shuttle product.
          if (shuttleProduct) {
            shuttleProduct.prices.forEach(price => {
              total += price.price * price.quantity
              number += price.quantity
            })
          }

          // Update pricesString.
          if (this.options['locale'] == 'fr') {
            pricesString = `${total}€ ${data.for_label} ${number} ${data.ticket_label}${number > 1 ? 's' : ''}`
          } else {
            pricesString = `€${total} ${data.for_label} ${number} ${data.ticket_label}${number > 1 ? 's' : ''}`
          }

          // Append prices string.
          this.ui.mobileInfosPrices.textContent = pricesString

          // Show it.
          this.ui.mobileInfosPrices.classList.remove('is-hidden')
        } else {
          this.ui.mobileInfosPrices.classList.add('is-hidden')
        }
      } else {
        this.ui.mobileInfosPrices.classList.add('is-hidden')
      }

      // If we have a date.
      if (this.dateSelected) {

        // If we have a date formatted.
        if (this.dateSelected.dateFormatted) {
          dateString = this.dateSelected.dateFormatted
        }

        // If we have an hour.
        if (this.dateSelected.hourStr) {
          dateString += ' – ' + this.dateSelected.hourStr
        }

        // Append date string.
        this.ui.mobileInfosDate.textContent = dateString

        // Show/hide correct elements.
        if (this.ui.mobileInfosText) this.ui.mobileInfosText.classList.add('is-hidden')
        this.ui.mobileInfosDate.classList.remove('is-hidden')
      }

      // If we don't have any date and any product.
      if (!this.dateSelected && (!this.cart.products || !this.cart.products.length)) {
        if (this.ui.mobileInfosText) this.ui.mobileInfosText.classList.remove('is-hidden')
        this.ui.mobileInfosDate.classList.add('is-hidden')
      }

      if (this.productType == 'membership') {

        if (this.step == 2) {

          this.ui.mobileStepTitle.textContent = this.productMembership == 'renew' ? this.ui.formMembershipTitleRenew.textContent : this.ui.formMembershipTitleNew.textContent
          this.ui.formMembershipTitleNew.classList.add('is-hidden-mobile')
          this.ui.formMembershipTitleRenew.classList.add('is-hidden-mobile')

          this.ui.submitMembership.classList.add('is-hidden-mobile')
          this.ui.mobileFooter.classList.add('is-membership')

        } else {

          this.ui.formMembershipTitleNew.classList.remove('is-hidden-mobile')
          this.ui.formMembershipTitleRenew.classList.remove('is-hidden-mobile')
          this.ui.submitMembership.classList.remove('is-hidden-mobile')
          this.ui.mobileFooter.classList.remove('is-membership')
        }
      } else {
        this.ui.formMembershipTitleNew.classList.remove('is-hidden-mobile')
        this.ui.formMembershipTitleRenew.classList.remove('is-hidden-mobile')
        this.ui.submitMembership.classList.remove('is-hidden-mobile')
        this.ui.mobileFooter.classList.remove('is-membership')
      }
    },
    updateUIInfos () {

      // Create fake products list to add current one.
      let newProducts = JSON.parse(JSON.stringify(this.cart.products))

      // Init variable.
      let productsHasCurrentProduct = false

      // If we don't have current product inside our product
      for (let i = 0, j = this.cart.products.length; i < j; i++) {
        if (this.currentProduct && this.currentProduct.productId === this.cart.products[i].productId) {
          productsHasCurrentProduct = true
        }
      }

      // Add current product if we don't have it.
      if (!productsHasCurrentProduct && this.currentProduct) {
        newProducts.push({
          productId: this.currentProduct.productId,
          title: this.currentProduct.title,
        })
      }

      // Build markup.
      const markup = ListCart({
        title: this.ui.infos.getAttribute('data-cart-infos-title'),
        items: newProducts,
        label_total: this.ui.infos.getAttribute('data-cart-infos-label-total'),
        locale: this.options['locale'],
        variation: (this.cart.products.length > 1 || (this.cart.products.length === 1 && !productsHasCurrentProduct)) ? 'multiple' : '',
      })

      // Append markup.
      this.ui.infos.innerHTML = markup

      let showPricesText = true

      if (this.currentProduct && this.currentProduct.event && (this.currentProduct.event.showPricesText === false)) {
        showPricesText = false
      }

      const sidebarPriceInfoMarkup = SidebarPriceInfo({
        showPricesText: showPricesText,
        text: this.ui.priceinfo.getAttribute('data-cart-priceinfo-text'),
      })

      this.ui.priceinfo.innerHTML = sidebarPriceInfoMarkup

      // Show infos cart.
      this.ui.infos.classList.remove(this.sidebarInfosHiddenKlass)
    },
    updateUISteps () {
      // Hide all steps
      Array.from(this.ui.steps).map((step, index) => {
        if (index + 1 != this.step) {
          this.ui.steps[index].classList.remove(this.stepVisibleKlass)
        }
      })

      // Update step height.
      this.updateStepHeight()

      // Show current step.
      setTimeout(() => {

        // If sidebar content exist, scroll top.
        if (this.ui.sidebarContent) {
          this.ui.sidebarContent.scroll(0, 0)
        }

        // If sidebar scroll exist, scroll top.
        if (this.ui.sidebarScroll) {
          this.ui.sidebarScroll.scroll(0, 0)
        }

        this.ui.steps[this.step - 1].classList.add(this.stepVisibleKlass)
      }, 350)

      // Update step inside mobile header.
      this.ui.mobileStepCurrent.innerHTML = this.productType == 'membership' ? this.step - 1 : this.step
      this.ui.mobileStepTotal.innerHTML = this.productType == 'membership' ? 2 : 3

      // Remove loader.
      // this.ui.sidebar.classList.remove(this.sidebarLoadingKlass);
    },
    updateStepHeight () {
      // Set height.
      setTimeout(() => {
        if (this.ui.steps[this.step - 1].offsetHeight !== this.ui.stepContainer.offsetHeight) {
          this.ui.stepContainer.style.height = this.ui.steps[this.step - 1].offsetHeight + 'px'
        }
      }, 20)
    },
    updateUIBreadcrumb () {

      // Get breadcrumb items.
      const breadcrumbItems = (this.productType === 'membership') ? this.ui.breadcrumbMembership.querySelectorAll('.breadcrumb__item') : this.ui.breadcrumb.querySelectorAll('.breadcrumb__item')

      // If we have a starting step.
      if (this.startingStep && this.startingStep === 3) {

        this.ui.breadcrumb.classList.remove('is-hidden')
        this.ui.breadcrumbMembership.classList.add('is-hidden')

        // Add hidden class on active breadcrumb items.
        Array.from(breadcrumbItems).map((item, index) => {
          item.classList.add(this.breadcrumbDisableKlass)
          item.classList.add(this.breadcrumbHiddenKlass)
        })

      } else if (this.productType === 'membership') {

        this.ui.breadcrumb.classList.add('is-hidden')
        this.ui.breadcrumbMembership.classList.remove('is-hidden')

        // Remove disable class on active breadcrumb items.
        Array.from(breadcrumbItems).map((item, index) => {
          if (index === 0) {
            item.classList.add(this.breadcrumbHiddenKlass)
          } else {
            item.classList.remove(this.breadcrumbHiddenKlass)
          }

          if (this.step < index + 1) {
            item.classList.add(this.breadcrumbDisableKlass)
          } else {
            item.classList.remove(this.breadcrumbDisableKlass)
          }
        })

      } else {

        this.ui.breadcrumb.classList.remove('is-hidden')
        this.ui.breadcrumbMembership.classList.add('is-hidden')

        // Remove disable class on active breadcrumb items.
        Array.from(breadcrumbItems).map((item, index) => {
          item.classList.remove(this.breadcrumbHiddenKlass)

          if (this.step < index + 1) {
            item.classList.add(this.breadcrumbDisableKlass)
          } else {
            item.classList.remove(this.breadcrumbDisableKlass)
          }
        })
      }
    },
    refreshProductDates (resetErrorBanner = false) {
      if (!this.currentProduct) {
        return
      }

      let productId = this.currentProduct.productId

      if (this.currentProduct.eventId) {
        productId += ',' + this.currentProduct.eventId
      }

      setTimeout(() => {
        this.dateSelected.hourStr = null
      }, 1000)

      this.APIProductGetDates({
        productId: productId,
        resetErrorBanner: resetErrorBanner,
      })

    },
    getLocalStorage () {
      // Get cart from local storage.
      const cartFromLocalStorage = localStorage.getItem('cart')

      // If we don't have any local storage, return.
      if (!cartFromLocalStorage) return

      // Get cart object.
      const cartObj = JSON.parse(cartFromLocalStorage)

      // If we have a validity and it's not expired.
      if (cartObj.validity && cartObj.validity > Date.now() && cartObj.locale && cartObj.locale === document.documentElement.getAttribute('lang')) {
        this.cart = cartObj
      } else {
        localStorage.removeItem('cart')
      }
    },
    setLocalStorage () {
      localStorage.setItem('cart', JSON.stringify(this.cart))
    },
    handleGetPrices () {

      // Build prices.
      this.buildPrices()

      if (this.productType == 'membership') {
        // Update Cart Object
        this.updateCartObject()
      }

      // Validage steps.
      this.stepsValidation()

      // Update UI.
      this.updateCartUI()

      // Remove loader.
      setTimeout(() => {
        this.ui.sidebar.classList.remove(this.sidebarLoadingKlass)
      }, this.sidebarTransitionDuration)
    },
    handleAddProduct (newProduct, entries) {
      for (let i = 0, j = this.cart.products.length; i < j; i++) {
        if (newProduct.showId === this.cart.products[i].showId) {
          if (this.cart.products[i].entries && this.cart.products[i].entries.length) {
            this.cart.products[i].entries.concat(entries)
          } else {
            this.cart.products[i].entries = entries
          }
        }
      }

      // Set local storage.
      this.setLocalStorage()
    },

    handleCartUpdate (params) {

      // If we want to close the sidebar.
      if (params.closeSidebar) {

        // Close sidebar.
        document.dispatchEvent(new CustomEvent('sidebar:closeFromOutside'))

        // Emit custom event
        document.dispatchEvent(new CustomEvent('cart:updated', {
          detail: {
            cart: this.cart,
            latestProductAdded: this.latestProductAdded,
            openCartMini: params.openCartMini,
          },
        }))

        // When sidebar is closed.
        setTimeout(() => {

          // Reset sidebar.
          this.resetSidebar()

        }, this.sidebarTransitionDuration)

      } else {

        // Emit custom event
        document.dispatchEvent(new CustomEvent('cart:updated', {
          detail: {
            cart: this.cart,
            latestProductAdded: this.latestProductAdded,
          },
        }))

        // Launch APICartCheckAmount.
        this.APICartCheckAmount()

      }
    },
    orderCompletedHandler () {
      // Reset sidebar.
      this.resetSidebar()

      // Reset currentProduct.
      this.currentProduct = null

      // Reset cart object.
      this.cart = {
        locale: document.documentElement.getAttribute('lang'),
        products: [],
      }
    },
    // region API
    APICartCreate (params = {}) {
      // Update params.
      params = {
        closeSidebar: params.closeSidebar ? params.closeSidebar : false,
        openCartMini: params.openCartMini ? params.openCartMini : false,
      }

      // Reset error banner.
      this.resetErrorBanner()

      this.waitForSession(() => {
        // Get prices from API.
        ax({
          method: 'POST',
          url: `${this.APIUrl.cartCreate}${this.localeParam}`,
        }).then((res) => {

          // Create cart object.
          this.cart.cartId = res.data.response.cartId

          // Set validity.
          this.cart.validity = (Date.now() + (res.data.response.expirationDelay * 60 * 1000))

          // Add first product to cart.
          this.APIProductAdd({
            closeSidebar: params.closeSidebar,
            openCartMini: params.openCartMini,
          })
        }).catch((error) => {
          this.APIHandleErrors('cart-create', error)
        })
      })
    },
    APICartGetInfo () {
      this.waitForSession(() => {
        // Delete cart on the FE.
        ax({
          method: 'GET',
          url: `${this.APIUrl.cartGet}${this.cart.cartId}${this.localeParam}`,
        }).then((res) => {
          // console.log('cart info : ', res);
        }).catch((error) => {
          this.APIHandleErrors('cart-get', error)
        })
      })
    },
    APICartCheckAmount () {
      /*
         We first need to calculate the total amount of the cart on the FE.
         When we have the total amount, we need to compare that with the amount of the cart at the BE level (which is the source of truth)
         1. If it's the same, it means that it's clean and up-to-date on the FE, so we can go further.
         2. If it's not, it means we need to destroy the cart and refresh the page because it's outdated.
      */
      let cartTotalAmount = 0
      this.cart.products.forEach(product => {
        product.prices.forEach(price => {
          cartTotalAmount += price.price * price.quantity
        })
      })

      // Reset error banner.
      this.resetErrorBanner()

      this.waitForSession(() => {
        // Get prices from API.
        ax({
          method: 'POST',
          url: `${this.APIUrl.cartCheckAmount}${this.cart.cartId}/pre-transaction${this.localeParam}`,
          data: {
            totalAmountCents: cartTotalAmount * 100,
          },
        }).then((res) => {
          // Save informations.
          this.transactionInfos = {
            totalAmountCents: res.data.response.totalAmountCents,
            transactionId: res.data.response.transactionId,
          }

          // Based on total amount, launch payment intent or just go to next step.
          if (this.transactionInfos.totalAmountCents === 0) {

            // Hide payment block.
            this.ui.paymentBlock.classList.add('is-hidden')

            // Doesn't send payment methods,
            // because they're unneeded for free payments.
            document.dispatchEvent(new CustomEvent('adyen:paymentStart', {
              detail: {
                cart: this.cart,
                transactionInfos: this.transactionInfos,
                // sessionId: this.options['session-id']
              },
            }))

            // Remove loading state on sidebar.
            this.ui.sidebar.classList.remove(this.sidebarLoadingKlass)

          } else {

            // Show payment block.
            this.ui.paymentBlock.classList.remove('is-hidden')

            // Remove loading state on sidebar.
            this.ui.sidebar.classList.remove(this.sidebarLoadingKlass)

            document.dispatchEvent(new CustomEvent('adyen:paymentStart', {
              detail: {
                cart: this.cart,
                paymentMethodsResponse: res.data.response.paymentMethods,
                transactionInfos: this.transactionInfos,
                // sessionId: this.options['session-id']
              },
            }))
          }

        }).catch((error) => {
          this.APIHandleErrors('cart-check-amount', error)
        })
      })
    },
    APICartDelete () {
      // Reset error banner.
      this.resetErrorBanner()

      this.waitForSession(() => {
        // Delete cart on the FE.
        ax({
          method: 'POST',
          url: `${this.APIUrl.cartDelete}${this.cart.cartId}${this.localeParam}`,
          data: {
            _method: 'DELETE',
          },
        }).then((res) => {
          if (localStorage.getItem('cart')) {
            localStorage.removeItem('cart')
          }
        }).catch((error) => {
          this.APIHandleErrors('cart-delete', error)
        })
      })
    },
    APIProductGetDates (params = {}) {

      let resetErrorBanner = true
      if ('resetErrorBanner' in params) {
        resetErrorBanner = params.resetErrorBanner
      }
      // Update params.
      params = {
        productId: params.productId ? params.productId : null,
      }
      // Reset error banner.
      if (resetErrorBanner) {
        this.resetErrorBanner()
      }

      this.waitForSession(() => {
        // Get dates from API.
        ax({
          method: 'GET',
          url: `${this.APIUrl.productGet}${params.productId}/dates${this.localeParam}`,
          cancelToken: this.APICancelTokenCreate().token,
        }).then((res) => {

          // Save current product.
          this.currentProduct = res.data.response

          // Check if product is already added.
          this.isProductAlreadyAdded()

        }).catch((error) => {
          this.APIHandleErrors('product-get-dates', error)
        })
      })
    },
    APIProductGetPrices () {
      // Reset error banner.
      this.resetErrorBanner()

      let url = `${this.APIUrl.productGetPrices}shows/${this.showId}/prices${this.localeParam}`
      let cancelToken = this.APICancelTokenCreate().token

      this.waitForSession(() => {
        // Get prices from API.
        ax({
          method: 'GET',
          url,
          cancelToken,
        }).then((res) => {
          let prices = res.data.response

          if (!prices || (prices && prices.length == 0)) {
            // Reset selected hours to force re-validate
            this.dateSelected.hourStr = null
            this.APIHandleErrors('product-get-prices', window?.A17?.cartLabels?.noTickets)
          }

          // Save product prices.
          this.currentProduct.prices = prices

          // For each price, add showId.
          this.currentProduct.prices.map(price => {
            price.showId = this.showId
          })

          let getShuttleDates = (this.currentProduct && this.currentProduct.event && this.currentProduct.event.is_online) ? false : true

          // If we have a shuttle product.
          if (this.options['shuttle-id'] && this.productType !== 'membership' && getShuttleDates) {

            // Get shuttle dates.
            this.APIProductGetShuttleDates()

          } else {

            // Handle get prices.
            this.handleGetPrices()
          }

        }).catch((error) => {
          this.APIHandleErrors('product-get-prices', error)
        })
      })
    },
    APIProductGetShuttleDates () {

      let url = `${this.APIUrl.productGet}${this.options['shuttle-id']}/dates${this.localeParam}`
      let cancelToken = this.APICancelTokenCreate().token

      this.waitForSession(() => {
        // Get dates from API.
        ax({
          method: 'GET',
          url,
          cancelToken,
        }).then((res) => {

          // Save shuttle.
          this.shuttle = res.data.response

          // Init hasSameDate variable.
          let hasSameDate = false

          // If we have a date matching the selected one from the event
          if (this.dateSelected && this.dateSelected.dateStr && this.dateSelected.hourStr) {
            this.shuttle.dates.map(date => {
              if (date.day === this.dateSelected.dateStr) {
                if (date.hours[0].start_date <= this.dateSelected.startDate && date.hours[0].end_date >= this.dateSelected.startDate) {
                  this.shuttle.showId = date.hours[0].show_id
                  hasSameDate = true
                }
              }
            })
          }

          // If we have the same date.
          if (hasSameDate) {
            this.APIProductGetShuttlePrices()
          } else {
            this.handleGetPrices()
          }

        }).catch((error) => {
          this.APIHandleErrors('product-get-shuttle-dates', error)
        })
      })
    },
    APIProductGetShuttlePrices () {

      let url = `${this.APIUrl.productGetPrices}shows/${this.shuttle.showId}/prices${this.localeParam}`
      let cancelToken = this.APICancelTokenCreate().token

      this.waitForSession(() => {
        ax({
          method: 'GET',
          url,
          cancelToken,
        }).then((res) => {
          // Init shuttle price.
          let shuttlePrice = res.data.response[0]

          // If we have shuttle price.
          if (shuttlePrice) {
            shuttlePrice.showId = this.shuttle.showId
            this.shuttle.prices = [shuttlePrice]
          }
          // Handle get prices.
          this.handleGetPrices()

        }).catch((error) => {
          this.APIHandleErrors('product-get-shuttle-prices', error)
        })
      })
    },
    APIProductAdd (params = {}) {

      // Update params.
      params = {
        productId: params.productId ? params.productId : null,
        closeSidebar: params.closeSidebar ? params.closeSidebar : false,
        openCartMini: params.openCartMini ? params.openCartMini : false,
      }

      // Create target product (default the first one when we create the cart for the first time).
      let targetProduct = this.cart.products[0]

      // If we pass a product id, it means we want to add a specific one
      if (params.productId) {
        for (let i = 0, j = this.cart.products.length; i < j; i++) {
          if (this.cart.products[i].productId === params.productId) {
            targetProduct = this.cart.products[i]
          }
        }
      }

      // Force target product on membership passes
      if (this.productType == 'membership') {
        targetProduct = this.currentProduct
        targetProduct.showId = this.currentProduct.dates[0].hours[0].show_id
        targetProduct.prices[0].quantity = 1
      }

      // Reset error banner.
      this.resetErrorBanner()

      let url = `${this.APIUrl.productAdd}${this.cart.cartId}${this.localeParam}`

      this.waitForSession(() => {

        // Add product to cart from API.
        ax({
          method: 'POST',
          url,
          data: {
            showId: targetProduct.showId,
            entries: targetProduct.prices.map(price => {
              return {
                nbSeats: price.quantity,
                priceId: price.priceId,
              }
            }),
          },
        }).then((res) => {

          // Save entries into local storage.
          this.handleAddProduct(targetProduct, res.data.response.entries)

          // Init shuttleProduct.
          let shuttleProduct = null

          // If we have a shuttle happening at the same day, add it.
          this.cart.products.forEach(product => {
            if (product.type === 'shuttle' && targetProduct.dateSelected.dateStr === product.dateSelected.dateStr) {
              shuttleProduct = product
            }
          })

          // If we have a shuttle product.
          if (shuttleProduct) {

            // Init quantity.
            let quantity = shuttleProduct.prices[0].quantity

            // Check if we already have entries.
            if (shuttleProduct.entries && shuttleProduct.entries.length) {
              quantity = shuttleProduct.prices[0].quantity - shuttleProduct.entries.length
            }

            // Check if we need to remove some of them.
            if (quantity > 0) {
              this.APIProductAddShuttle(params, shuttleProduct, quantity)
            } else if (quantity < 0) {
              this.APIProductDeleteShuttle(params, shuttleProduct, Math.abs(quantity))
            } else {
              this.handleCartUpdate(params)
            }
          } else {
            this.handleCartUpdate(params)
          }

        }).catch((error) => {
          this.APIHandleErrors('product-add', error)
        })
      })
    },
    APIProductAddShuttle (params, shuttleProduct, quantity) {

      let url = `${this.APIUrl.productAdd}${this.cart.cartId}${this.localeParam}`

      this.waitForSession(() => {
        // Add shuttleProduct to cart from API.
        ax({
          method: 'POST',
          url,
          data: {
            showId: shuttleProduct.showId,
            entries: shuttleProduct.prices.map(price => {
              return {
                nbSeats: quantity,
                priceId: price.priceId,
              }
            }),
          },
        }).then((res) => {
          // Save entries into local storage.
          this.handleAddProduct(shuttleProduct, res.data.response.entries)

          // Do other stuff after.
          this.handleCartUpdate(params)

        }).catch((error) => {
          this.APIHandleErrors('product-add-shuttle', error)
        })
      })
    },
    APIProductDeleteShuttle (params, shuttleProduct, quantity) {
      // Init current entry count.
      let currentEntriesCount = 0

      // Reset error banner.
      this.resetErrorBanner()

      // Loop through each of them and remove them to the API.
      for (let i = 0, j = quantity; i < j; i++) {

        let url = `${this.APIUrl.productEdit}${this.cart.cartId}/entries/${this.shuttleProduct.entries[i].id}${this.localeParam}`

        this.waitForSession(() => {
          // Send cartId to BE and get the total amount.
          ax({
            method: 'POST',
            url,
            data: {
              _method: 'DELETE',
            },
          }).then((res) => {

            // Increment currentEntriesCount.
            currentEntriesCount++

            // Delete each entry of the product.
            if (currentEntriesCount === nbOfEntries) {

            }

          }).catch((error) => {
            this.APIHandleErrors('product-shuttle-delete', error)
          })
        })
      }
    },
    APIProductEdit (params = {}) {
      // Update params.
      params = {
        closeSidebar: params && params.closeSidebar ? params.closeSidebar : false,
        openCartMini: params && params.openCartMini ? params.openCartMini : false,
      }

      // Init current entry count.
      let currentEntriesCount = 0

      // Get number of entries inside productEdited.
      const nbOfEntries = this.productEdited && this.productEdited.entries ? this.productEdited.entries.length : 0

      // Reset error banner.
      this.resetErrorBanner()

      // Loop through each of them and remove them to the API.
      for (let i = 0, j = this.productEdited && this.productEdited.entries ? this.productEdited.entries.length : 0; i < j; i++) {

        let url = `${this.APIUrl.productEdit}${this.cart.cartId}/entries/${this.productEdited.entries[i].id}${this.localeParam}`

        this.waitForSession(() => {
          // Send cartId to BE and get the total amount.
          ax({
            method: 'POST',
            url,
            data: {
              _method: 'DELETE',
            },
          }).then((res) => {

            // Increment currentEntriesCount.
            currentEntriesCount++

            // Delete each entry of the product.
            if (currentEntriesCount === nbOfEntries) {
              this.APIProductAdd({
                productId: this.productEdited.productId,
                closeSidebar: params.closeSidebar,
                openCartMini: params.openCartMini,
              })
            }

          }).catch((error) => {
            this.APIHandleErrors('product-edit', error)
          })
        })
      }
    },
    APIProductDelete (params = {}) {

      // Update params.
      params = {
        productIndex: params && params.productIndex ? params.productIndex : null,
        item: params && params.item ? params.item : null,
      }

      // Init current entry count.
      let currentEntriesCount = 0

      // Get number of entries inside productEdited.
      const nbOfEntries = this.productEdited && this.productEdited.entries ? this.productEdited.entries.length : 0

      // Reset error banner.
      this.resetErrorBanner()

      // Loop through each of them and remove them to the API.
      for (let i = 0, j = this.productEdited && this.productEdited.entries ? this.productEdited.entries.length : 0; i < j; i++) {

        let url = `${this.APIUrl.productEdit}${this.cart.cartId}/entries/${this.productEdited.entries[i].id}${this.localeParam}`

        this.waitForSession(() => {

          // Delete each entry of the product.
          ax({
            method: 'POST',
            url,
            data: {
              _method: 'DELETE',
            },
          }).then((res) => {

            // Increment currentEntriesCount.
            currentEntriesCount++

            // If we delete all the entries.
            if (currentEntriesCount === nbOfEntries) {

              // Get product from cart.
              let products = JSON.parse(JSON.stringify(this.cart.products))

              // Remove deleted one.
              products.splice(params.productIndex, 1)

              // Replace cart products.
              this.cart.products = JSON.parse(JSON.stringify(products))

              // Reset the cart if it is now empty
              // (A new cartId will be created for it whenever a new product gets added to cart)
              if (this.cart.products.length === 0) {
                this.cart = {
                  locale: document.documentElement.getAttribute('lang'),
                  products: [],
                }
              }

              // Set local storage.
              this.setLocalStorage()

              // Emit custom event
              document.dispatchEvent(new CustomEvent('cart:updated', {
                detail: {
                  cart: this.cart,
                },
              }))

              // If we have an item, hide it.
              if (params.item) {
                params.item.classList.add('is-hidden')
              }

              // Reset product edited.
              this.productEdited = null
            }

          }).catch((error) => {
            this.APIHandleErrors('product-delete', error)
          })
        })
      }
    },
    APIPaymentIntent () {
      // Reset error banner.
      this.resetErrorBanner()

      let url = `${this.APIUrl.paymentIntent}${this.cart.cartId}/payment-intent${this.localeParam}`
      let data = this.transactionInfos

      this.waitForSession(() => {
        // Send cartId to BE and get the total amount.
        ax({
          method: 'POST',
          url,
          data,
        }).then((res) => {
          // Emit event for stripe behavior.
          // document.dispatchEvent(new CustomEvent('stripe:clientSecret', {
          //   detail: {
          //     clientSecret: res.data.response.client_secret,
          //     cart: this.cart,
          //     // sessionId: this.options['session-id']
          //   }
          // }));

          // Remove loading state on sidebar.
          this.ui.sidebar.classList.remove(this.sidebarLoadingKlass)

        }).catch((error) => {
          this.APIHandleErrors('payment-intent', error)
        })
      })
    },
    APIHandleErrors (from, error) {
      // Init message.
      let message = ''

      // If it comes from Axios canceling a request, do nothing.
      if (axios.isCancel(error)) {
        this.ui.sidebar.classList.remove(this.sidebarLoadingKlass)
        return
      }

      // Based on the type of error we get, update message.
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message
      } else if (error.request) {
        if (error.request.response) {
          try {
            const res = JSON.parse(error.request.response)
            message = res.response.message
          } catch (err) {
            console.error('APIHandleErrors : Response may not be JSON', 'Original error:', error, 'Syntax error?', err)
            message = error.request.statusText
          }
        } else {
          message = error.request.statusText
        }
      } else if (error.message) {
        message = error.message
      } else {
        message = error
      }

      // Append text error inside banner.
      // console.log('this.ui.bannerText.textContent = ', message)
      // console.trace();
      this.ui.bannerText.textContent = message

      // Show banner.
      this.ui.banner.classList.add('is-visible')
      this.updateStepHeight()

      // Based on where the request comes from, do something special.
      switch (from) {
        case 'product-get-dates':
        case 'product-get-prices':
        case 'cart-check-amount':
        case 'cart-create':
        case 'product-add':
        case 'product-edit':
        case 'product-delete':
        case 'payment-intent':
        case 'session':
        case 'transaction':
          localStorage.removeItem('cart')
          this.step = 1
          this.cart = {
            locale: document.documentElement.getAttribute('lang'),
            products: [],
          }
          this.updateUISteps()
          this.updateUIInfos()
          this.updateUIBreadcrumb()
          document.dispatchEvent(new CustomEvent('cart:updated', {
            detail: {
              cart: this.cart,
            },
          }))

          if (!this.currentProduct) {
            document.dispatchEvent(new CustomEvent('sidebar:closeFromOutside'))
          }
          setTimeout(() => {
            this.refreshProductDates(false)
          }, 1000)
          break
      }

      // Remove loader.
      this.ui.sidebar.classList.remove(this.sidebarLoadingKlass)
    },
    APICancelTokenCreate () {
      // Generate token from axio.
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      // Push token into requests array.
      this.requests.push(source)

      // Return source.
      return source
    },
    // endregion API
    stripErrorHandler (e) {
      this.APIHandleErrors('stripe', e.detail.error)
    },
    adyenErrorHandler (e) {
      this.APIHandleErrors('adyen', e.detail.error)
    },
    transactionErrorHandler (e) {
      this.APIHandleErrors('transaction', e.detail.error)
    },
    resetErrorBanner () {
      // Remove wording inside it.
      // console.log('this.ui.bannerText.textContent = ', '');
      // console.trace()
      this.ui.bannerText.textContent = ''

      // Hide banner.
      this.ui.banner.classList.remove('is-visible')

      this.updateStepHeight()
    },
    clearRequests () {
      // If we have some requests.
      if (this.requests && this.requests.length) {

        // Loop through each of them and cancel it.
        this.requests.forEach(request => {
          request.cancel('Request canceled')
        })

        // Reset array.
        this.requests = []
      }
    },
    confirmOrder (redirectUrl) {
      // Remove cart from local storage.
      localStorage.removeItem('cart')

      // Close sidebar.
      document.dispatchEvent(new CustomEvent('sidebar:closeFromOutside'))

      // Hide cart mini
      document.dispatchEvent(new CustomEvent('cart:updated'))

      // Emit event to cart behavior to reset it.
      document.dispatchEvent(new CustomEvent('stripe:orderCompleted'))

      // When sidebar is closed, redirect.
      setTimeout(() => {

        // Redirect user to the order summary page.
        barba.go(redirectUrl)

      }, this.sidebarTransitionDuration)
    },
    handleWindowResize () {
      // For step height resize
      this.updateStepHeight()
    },
    handleAdyenRelayout () {
      // For step height resize
      this.updateStepHeight()
    },
    resetSidebar () {
      // Reset step.
      this.step = 1

      // Reset starting step.
      this.startingStep = null

      // Reset date selected.
      this.dateSelected = null

      // Reset latest product added.
      this.latestProductAdded = null

      // Reset productEdited varible.
      this.productEdited = null

      // Empty membership products from cart
      if (this.productType == 'membership') {
        if (this.cart.products) {
          for (let i = 0, j = this.cart.products.length; i < j; i++) {
            if (this.cart.products[i].type == 'membership') {
              this.cart.products.splice(i, 1)
            }
          }

          this.ui.formMembershipForm?.reset()
          this.ui.formMemberListenInputs?.forEach(input => {
            input?.removeEventListener('input', this.onMemberShipInputChange)
          })
        }
      }
      this.setLocalStorage()

      // Reset product type.
      this.productType = null
      this.productMembership = null

      // Reset shuttle.
      this.shuttle = null

      // Clear requets.
      this.clearRequests()

      // Reset error banner.
      this.resetErrorBanner()

      // Update UI steps.
      this.updateUISteps()

    },
    unbindEvents () {
      // Remove event listener for sidebar open.
      document.removeEventListener('sidebar:open', this.sidebarOpenHandler)

      // Remove event listener when we close the sidebar.
      document.removeEventListener('sidebar:close', this.sidebarCloseHandler)

      // Remove event listener for datepicker updated.
      document.removeEventListener('datepicker:updated', this.datepickerUpdatedHandler)

      // Remove event listener when page is updated.
      document.removeEventListener('page:updated', this.rebindUIandEvents)

      // Remove event listener for Stripe errors.
      document.removeEventListener('stripe:error', this.stripErrorHandler)

      // Remove event listener for Adyen errrors.
      document.removeEventListener('adyen:error', this.adyenErrorHandler)

      document.removeEventListener('transaction:error', this.transactionErrorHandler)

      // Remove event when order is completed.
      document.removeEventListener('stripe:orderCompleted', this.orderCompletedHandler)

      document.removeEventListener('adyen:confirmOrder', this.handleAdyenConfirmOrder)

      document.removeEventListener('adyen:transactionStarted', this.handleAdyenTransactionStarted)

      // Add change event on input membership.
      if (this.ui.inputsMembership && this.ui.inputsMembership.length) {
        Array.from(this.ui.inputsMembership).map(input => {
          input.removeEventListener('input', this.inputMembershipHandler)
        })
      }

      // Remove click event on step btns.
      if (this.ui.stepsBtns) {
        Array.from(this.ui.stepsBtns).map(btn => {
          btn.removeEventListener('click', this.stepBtnClickHandler)
        })
      }

      // If we have some price inputs.
      if (this.ui.priceInputs) {
        Array.from(this.ui.priceInputs).map(input => {
          input.removeEventListener('change', this.updateCartObject)
        })
      }

      // Remove click event on add to bag btn.
      if (this.ui.addToCartBtn) {
        this.ui.addToCartBtn.removeEventListener('click', this.addToCart)
      }

      window.removeEventListener('resize', this.handleWindowResize)
      document.removeEventListener('adyen:relayout', this.handleAdyenRelayout)
      this.node.removeEventListener('datepicker-single:loading', this.handleDatepickerSingleLoading, false)
      this.node.removeEventListener('datepicker-single:loaded', this.handleDatepickerSingleLoaded, false)
    },
    handleDatepickerSingleLoading () {
      // this.currentStepIsLoading = true;
      this.ui.calender = document.querySelector('.flatpickr-calendar')

      if (this.step === 1 && this.ui.calender) {
        this.ui.calender.classList.add('is-loading-hours')
      }
    },
    handleDatepickerSingleLoaded () {
      // this.currentStepIsLoading = true;
      this.analytics('horaire')
      if (this.step === 1 && this.ui.calender) {
        this.ui.calender.classList.remove('is-loading-hours')
      }
    },
    waitForSession (cb) {
      cb()
    },
  },
  {
    init () {
      this.sidebarId = 'sidebar-cart'
      this.sidebarTransitionDuration = 400
      this.sidebarLoadingKlass = 'is-loading'
      this.stepVisibleKlass = 'is-visible'
      this.stepTransitionKlass = 'has-transition'
      this.breadcrumbDisableKlass = 'is-disable'
      this.breadcrumbHiddenKlass = 'is-hidden'
      this.sidebarDateHiddenKlass = 'is-hidden'
      this.sidebarInfosHiddenKlass = 'is-hidden'
      this.step = 1

      // Shuttle
      this.shuttle = null

      // Edit mode.
      this.productEdited = null

      // Url API.
      this.APIUrl = {
        cartCreate: '/api/v1/online-sales/carts/',
        cartGet: '/api/v1/online-sales/carts/',
        cartCheckAmount: '/api/v1/online-sales/carts/',
        cartDelete: '/api/v1/online-sales/carts/',
        productGet: '/api/v1/online-sales/products/',
        productGetDates: '/api/v1/online-sales/products/',
        productGetPrices: '/api/v1/online-sales/',
        productAdd: '/api/v1/online-sales/carts/',
        productEdit: '/api/v1/online-sales/carts/',
        productDelete: '/api/v1/online-sales/carts/',
        paymentIntent: '/api/v1/online-sales/carts/',
      }

      // Save locale param.
      this.localeParam = `?locale=${this.options['locale'] ? this.options['locale'] : 'en'}`

      // Save transaction informations.
      this.transactionInfos = {
        totalAmountCents: null,
        transactionId: null,
      }

      // Save productType globally.
      this.productType = null
      this.productMembership = null

      // Save products.
      this.cart = {
        locale: document.documentElement.getAttribute('lang'),
        products: [],
      }

      // Save requests.
      this.requests = []

      // Save starting step.
      this.startingStep = null

      // Init last product added variable.
      this.latestProductAdded = null

      // Save currentProduct.
      this.currentProduct = null

      // Save current dates.
      this.dateSelected = null

      // Save showId.
      this.showId = null

      // Coerce as boolean
      this.options['should-empty'] = this.options['should-empty'] != null
      if (this.options['should-empty']) {
        localStorage.removeItem('cart')
      } else {
        // Read from local storage.
        this.getLocalStorage()
      }

      this.bindUI()
      this.bindEvents()
    },
    destroy () {
      this.unbindEvents()
    },
  },
)

export default cartBehavior
